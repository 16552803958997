import React from 'react'
import { Link, graphql } from 'gatsby'

import BaseLayout from '../templates/base'
import Head from '../components/head'
import SocialCard from '../components/social-card'
import PageHeader from '../components/page-header'

import config from '../../config/site-config'
import formatHtmlTitle from '../utils/formatHtmlTitle'

const BlogPage = ({data}) => (
  <BaseLayout>
    <Head title={formatHtmlTitle(`Blog`)} />
    <SocialCard title={formatHtmlTitle(`Blog`)} />
    <PageHeader title="Blog" />
    <ol className="post-list">
      {data.allMarkdownRemark.edges.map(post => {
        const id = post.node.id
        const title = post.node.frontmatter.title
        const description = post.node.frontmatter.description
        const excerpt = post.node.excerpt
        const link = config.blogPrefix + post.node.frontmatter.slug
        const linkTitle = "Read ”" + title + "”"
        
        return(
          <li key={id} className="post-list--item">
            <Link to={link} title={linkTitle} className="post-link">
              <h1 className="title">
                  {title}
              </h1>
              
              {
                description && <h2 className="description">
                  {description}
                </h2>
              }
              
              <p className="excerpt">{excerpt}</p>
            </Link>
          </li>
        )
      })}
    </ol>
  </BaseLayout>
)

export default BlogPage

export const blogQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog\/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 150)
          frontmatter {
            title
            description
            slug
          }
        }
      }
    }
  }
`;
