import React from 'react'

import config from '../../config/site-config'

import GitHubIcon from '../assets/github.svg'
import MastodonIcon from '../assets/mastodon.svg'
import TwitterIcon from '../assets/twitter.svg'
import LinkedInIcon from '../assets/linkedin.svg'
import MailIcon from '../assets/mail.svg'
import RSSIcon from '../assets/rss.svg'

const year = new Date().getFullYear()

const Footer = () => (
	<div className="footer-wrapper" key="footer">
		<footer className="footer-container">
			<ul className="external-links--list">
				<li className="external-links--list-item">
					<a className="external-link" href={config.social.mastodon.url} title={"Follow " + config.social.mastodon.username + " on Mastodon"}>
						<MastodonIcon className="external-icon mastodon" />
						<span className="label">Mastodon</span>
					</a>
				</li>
				<li className="external-links--list-item">
					<a className="external-link" href={config.social.twitter.url} title={"Follow " + config.social.twitter.username + " on Twitter"}>
						<TwitterIcon className="external-icon twitter" />
						<span className="label">Twitter</span>
					</a>
				</li>
				<li className="external-links--list-item">
					<a className="external-link" href={config.social.github.url} title={"Follow " + config.social.github.username + " on GitHub"}>
						<GitHubIcon className="external-icon github" />
						<span className="label">GitHub</span>
					</a>
				</li>
				<li className="external-links--list-item">
					<a className="external-link" href={config.social.linkedin.url} title={"Connect with " + config.social.linkedin.username + " on LinkedIn"}>
						<LinkedInIcon className="external-icon linkedin" />
						<span className="label">LinkedIn</span>
					</a>
				</li>
				<li className="external-links--list-item">
					<a className="external-link" href={config.rss} title={"Subscribe to this blogʼs RSS feed"}>
						<RSSIcon className="external-icon rss" />
						<span className="label">RSS</span>
					</a>
				</li>
				<li className="external-links--list-item">
					<a className="external-link" href={config.social.email.url} title={`Send an email to ` + config.social.email.username}>
						<MailIcon className="external-icon mail" />
						<span className="label">Email</span>
					</a>
				</li>
			</ul>
			
			<p>
				Copyright © {year} Harshil Shah
			</p>
		</footer>
	</div>
)

export default Footer
